import { memo } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

import './styles.css';


const DesignRow = ( { navigation, variant = 1 } ) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const comps = [
    <span></span>,
  ];

  if (variant == 2) {
    return (
      <div className='varianttwo'>
        <div className='left2'>
          <div className='titol'>
            <div>// ABOUT US</div>
            <span>Clients</span> 
          </div>
          <hr className='line'/> 
        </div>
        <div className='right2'>
          <div>
            <p>
              <strong>We build partnerships</strong> that allow us to get to know every client's needs and offer solutions. 
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (variant == 3) {
      return (
        <div className='variantthree'>
          <div className='bgfilter'>
            <div className='title3'>
              {/* Lorem ipsum <span>dolore magna dolore</span> */}
              <Trans i18nKey={'designrow3.header'} components={comps} />
            </div>
            <p>
              {/* Ipsum dolor sit amet, elit, sed diam Lorem ipsum dolor sit amet, consectetuer adispiscing elit, sed diam */}
              {t('designrow3.desc')}
            </p>
            <div onClick={() => navigate('Contact')} className='contactdr'>Contact</div>
          </div>
        </div>
      );
  }

  return (
    <div className='extendedDesignRow'>{/** extra upper space */}
      <div className='individual'>
        <div className='left'>
        </div>
        <div className='center'>
          <div className='centerimg'>
          </div>
          <div className='centertext'>
            Let's do it!
          </div>
        </div>
        <div className='right'>
          <div className='titol'>
            <div>// OUR SERVICES</div>
            <span>Products</span> 
          </div>
          <hr className='line'/>
          <div>
            <p>
              We're currently developing new products and we're always looking for ideas!
            </p>
          </div>
        </div>

      </div>
        
    </div>
  );
}

export default memo(DesignRow);